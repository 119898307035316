@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.bodySection {
  margin: auto;
  color: #333;
  /* background: #f5f5f5; */
}
body {
  /* font-family: inherit; */
  font-size: 16px;
  color: #333;
  /* background-color: #f5f5f5 !important; */
}
iframe {
  display: none;
}
.adminControlApp {
  min-height: 1000px;
  /* display: inline-block; */
  background: #f5f5f5;
}
.layout-container {
  /* padding-top: 70px; */
  width: 100%;
  margin: auto;
  padding-bottom: 0px;
  background-color: #f6f9fd;
  min-height: 100vh;
}
.header-page-sec-top-full {
  border-bottom: 1px solid #e2e2e2;
}
.full-content-sec-page-design {
  width: 100%;
  display: flex;
  /* margin-top: 70px; */
  gap: 20px;
}
.doctor-dashboard-body-content {
  padding-top: 100px;
  /* overflow: scroll; */
}

.left-menu-side-content {
  width: 217px;
  background: #fff;
  box-shadow: 2px 2px 19px 0px rgba(0, 0, 0, 0.05);
  background: #fff;
  border-right: 2px solid #e2e2e2;
  display: flex;
  padding: 2rem;
  justify-content: left;
  position: fixed;
  height: 100vh;
}
.right-menu-side-content {
  max-width: 85vw;
  width: calc(100% - 217px);
  margin-left: 220px;
}
.cursor-pointer {
  cursor: pointer;
}
@media (max-width: 300px) {
  .layout-container {
    margin-top: 5rem;
  }
}
@media (max-width: 768px) {
  .full-content-sec-page-design {
    display: block;
  }
  .left-menu-side-content {
    width: 100%;
    display: none;
  }
  .right-menu-side-content {
    width: 100%;
    max-width: 100%;
    padding: 0px 10px;
    margin-left: 0px;
  }
}

body {
  background: #f6f9fd;
}

h2.sectionHeading {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}

input[placeholder^="Search"] {
  min-height: 40px;
}
