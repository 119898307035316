@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@media all and (min-width: 480px) {
  .LoginContainer {
    padding: 30px 0;
  }

  .LoginContainer form {
    margin: 0 auto;
    max-width: 320px;
  }
}
.LoginContainer {
  width: 500px;
  margin: auto !important;
}
.errorMessage {
  color: red;
}
.signup-md {
  width: 47%;
  float: left;
}
.signup-md-left {
  width: 47%;
  float: left;
  margin-left: 6%;
}
.SignupContainer {
  margin-top: 50px;
}
.loginsignupContainer {
  height: 100vh;
  width: 100vw;
  background: url(/static/media/loginBg.93bc5769.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 2rem;
  /* display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 3rem; */
}
.login-container {
  width: 500px;
  margin: auto;
}
.tabHeaderDesktop {
  width: 500px;
  margin: auto !important;
}
.data-loginsignupContainer {
  width: 100%;
  height: 100%;
  margin: auto;
}
.freshbag_signin_header {
  font-size: 28px;
  font-weight: 600;
  color: #000;
  /* margin-top: 5px!important; */
  text-align: center;
  margin-bottom: 20px;
}
.tabHeaderDesktop > div {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  /* padding-bottom: 13px; */
  cursor: pointer;
}
.tabHeaderDesktop .tabActive {
  border-bottom: 10px solid #000;
}
.tabInActive {
  border-bottom: 1px solid #dadada;
}
.data-loginsignupContainer > .row {
  margin: 0px;
}
.tabHeaderDesktop span {
  padding-bottom: 15px;
}
.LoginContainer .form-group .error.form-control {
  border-color: red;
}
.SignupContainer .form-group .invalid-feedback {
  display: inline;
}
.docImgWrapper {
  display: flex;
  margin: auto;
}
.docImgWrapper img {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  object-fit: none;
}
@media (max-width: 950px) {
  .right-menu-side-content {
    width: 100% !important;
  }
  .loginsignupContainer {
    width: 100%;
    height: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 0;
    gap: 0;
  }
  .docImgWrapper img {
    width: 100%;
    object-fit: contain;
  }
  .layout-container {
    width: 100% !important;
  }
}

.privacyPolicy {
  font-family: Roboto;
}
.bgImage {
  opacity: 0.1;
  position: fixed;
}
.privacyPolicy h1 {
  font-size: 3rem;
}
.privacyPolicy p {
  font-size: 1.5rem;
}

.loader_wrapper {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: rgb(0 0 0 / 42%);
  z-index: 999999999;
}

.css_loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.css_loader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 45px;
  height: 45px;
  margin: 5px;
  border: 5px solid #fff;
  border-radius: 50%;
  animation: css_loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.css_loader div:nth-child(1) {
  animation-delay: -0.45s;
}
.css_loader div:nth-child(2) {
  animation-delay: -0.3s;
}
.css_loader div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes css_loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media (max-width: 700px) {
  .section-container-header {
    height: auto !important;
  }
}
/* .select__menu,
.select__menu * {
  color: red;
  z-index: inherit;
  z-index: 9999999999999;
  background-color: #000;
} */
/* css loader ends */

.custom-modal .modal-title {
  color: #313131;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.48px;
}

.custom-modal .form-label {
  color: var(--dummy, #808080);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.3px;
  margin-top: 15px;
}

.custom-modal .form-control::placeholder {
  color: #ababab;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
}

.custom-modal .form-control {
  border-radius: 5px;
  border: 0.5px solid #b8b8b8;
  background: var(--white, #fff);
  height: 40px;
}

.custom-modal .form-control,
.custom-modal select.form-control {
  border-radius: 5px;
  border: 0.5px solid #b8b8b8;
  background: var(--white, #fff);
  font-size: 14px;
  color: #000;
}

.custom-modal select.form-control {
  padding: 0.375rem 0.75rem;
}

.custom-modal select.form-control option {
  font-size: 14px;
  color: #313131;
}

.cancelbtn {
  border-radius: 2px;
  background: var(--white, #fff);
  box-shadow: 2px 2px 19px 0px rgba(0, 0, 0, 0.07);
  color: var(--dummy, #808080);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.48px;
  border: #fff;
}

.save-btn {
  border-radius: 2px;
  background: var(--Primary, #9747ff);
  box-shadow: 2px 2px 19px 0px rgba(0, 0, 0, 0.1);
  padding: 11px 16px;
  color: var(--white, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.48px;
  border: #9747ff;
}

.whatsappbtn,
.mailbtn {
  display: flex;
  width: 100px;
  padding: 8px 15px;
  justify-content: center;
  align-items: center;
  grid-gap: 7px;
  gap: 7px;
  flex-shrink: 0;
  border-radius: 2px;
  background: var(--white, #fff);
  box-shadow: 2px 2px 19px 0px rgba(0, 0, 0, 0.1);
  color: var(--dummy, #808080);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #fff !important;
}

.edit-text {
  color: var(--dummy, #808080);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dropdown-menu {
  --bs-dropdown-min-width: auto !important;
}

.poppins-regular {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 400;
  }

  .poppins-medium {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 500;
  }

  .poppins-semibold {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 600;
  }

  .poppins-bold {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 700;
  }

  .flex_start {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .flex_center {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .flex_wrapbetween {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .inlineflex_start {
    display: inline-flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .inlineflex_center {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
  }

  .flexdirection_col {
    flex-direction: column;
  }
  

  .upload-prescriptionwrap {
    width: 92.352%;
    margin: 14px auto 14px auto;

    @media only screen and (min-width: 1024px) {
      margin: 17px auto 15px auto;
    }
  }
  .new-option-header {
    display: flex;
    justify-content: space-between;
    button {
    }
    h4 {
      width: 237px;
      position: relative;
      font-size: 24px;
      font-weight: 500;
      color: #000;
      text-align: left;
      display: inline-block;
      opacity: 0.8;
    }
  }
  .button-shadow {
    box-shadow: 2px 2px 19px 0px rgba(0, 0, 0, 0.1);
  }
  #uploadsubmitbtn:disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }

  .savedPrescription__list + label .savedPrescription__default {
    display: block;
    cursor: pointer;
  }
  .savedPrescription__list + label .savedPrescription__selected {
    display: none;
    cursor: pointer;
  }

  .savedPrescription__list:checked + label .savedPrescription__default {
    display: none;
    cursor: pointer;
  }
  .savedPrescription__list:checked + label .savedPrescription__selected {
    display: block;
    cursor: pointer;
  }
  .upload-container {
    width: 100%;
    margin-top: 30px;
    position: relative;
    border-radius: 10px;
    background-color: rgba(0, 165, 255, 0.1);
    border: 3px dashed #00a5ff;
    box-sizing: border-box;
    height: 271px;
    text-align: center;
    padding-top: 92px;
    cursor: pointer;

    &:hover {
      background: #00a5ff33;
    }
  }
  .progress-container {
    width: 100%;
    margin-top: 15px;
    height: 231px;
    border: 3px dashed #00a5ff;
    border-radius: 10px;
    cursor: pointer;
    background: rgba(0, 165, 255, 0.1);

    &:hover {
      background: #00a5ff33;
    }

    .progress-barwrap {
      width: 81.132%;
      margin: 0 auto;
      padding-top: 61px;

      .progress-bar {
        height: 1rem;
        margin-top: 40px;
        background: #d9d9d9;
        border-radius: 10px;
        overflow: hidden;
        width: 100%;

        .progress-div {
          transition-width: auto;
          transition-duration: 300ms;
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          background: #00a5ff;
          height: 100%;
        }
      }
    }
  }

  .savedprescription-containerwrap {
    .savedprescription-container {
      width: 100%;
      .savedprescription-wrap {
        display: grid;
        height: 471px;
        grid-row-gap: 1.25rem;
        row-gap: 1.25rem;
        overflow-y: scroll;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-column-gap: 1.25rem;
        column-gap: 1.25rem;

        &::-webkit-scrollbar {
          display: none;
        }

        @media only screen and (min-width: 1024px) {
          grid-template-columns: repeat(4, minmax(0, 1fr));
          grid-column-gap: 1.75rem;
          column-gap: 1.75rem;
        }

        .savedprescription-list {
          background: #ffffff;
          border-radius: 15px;
          width: 8rem;
          height: 8rem;

          @media only screen and (min-width: 1024px) {
            width: 10.125rem;
            height: 10.125rem;
          }

          .savedprescription-imgdata {
            position: relative;
            .savedprescription-img {
              width: 8rem;
              height: 8rem;
              object-fit: cover;
              border-radius: 15px;

              @media only screen and (min-width: 1024px) {
                width: 10.125rem;
                height: 10.125rem;
              }
            }

            .savedprescription-imgcheck {
              position: absolute;
              top: 1rem;
              right: 1.125rem;

              .hidden {
                display: none;
              }
            }
          }
        }
      }
      .savedprescription-btns {
        margin-top: 10px;
        width: 100%;

        .upload-btnwrap {
          button {
            -webkit-appearance: none;
                    appearance: none;
            padding: 7.5px 9px;
            border-radius: 10px;
            border: 1px solid #9747ff;
            font-size: 14px;
            line-height: 21px;
            color: #9747ff;
            background: #ffffff;

            @media only screen and (min-width: 1024px) {
              padding: 15px 18px;
            }
          }
        }

        .action-btnwrap {
          grid-gap: 18px;
          gap: 18px;
          margin-top: 12px;

          @media only screen and (min-width: 1024px) {
            grid-gap: 37px;
            gap: 37px;
          }

          button {
            -webkit-appearance: none;
                    appearance: none;
            border: medium none;
            font-size: 14px;
            line-height: 21px;
            padding: 7px 22px;
            border-radius: 15px;

            @media only screen and (min-width: 1024px) {
              padding: 13px 44px;
            }
          }

          .cancel-btn {
            color: #000000;
            opacity: 0.6;
            background: #ffffff;
          }

          .delete-btn {
            color: #ffffff;
            background: #9747ff;

            &:disabled {
              cursor: not-allowed;
              opacity: 0.3;
            }
          }
        }
      }
    }
  }
  .actions-btn-sec-content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 36px;
    margin-bottom: 16px;
    justify-content: flex-end;
    align-items: center;

    @media only screen and (min-width: 1024px) {
      justify-content: space-between;
      margin-top: 18px;
      margin-bottom: 8px;
    }

    .uploadprescriptions-leftbtns {
      grid-gap: 12px;
      gap: 12px;

      @media only screen and (min-width: 1024px) {
        grid-gap: 24px;
        gap: 24px;
      }
    }

    .uploadprescriptions-rightbtns {
      grid-gap: 12px;
      gap: 12px;
      margin-top: 6px;

      @media only screen and (min-width: 1024px) {
        grid-gap: 24px;
        gap: 24px;
        margin-top: 12px;
      }
    }
  }
  .saved-prescription-btn {
    position: relative;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #9747ff;
    box-sizing: border-box;
    width: 100%;
    padding: 7.5px 9px;
    color: #9747ff;
    white-space: nowrap;
    @media only screen and (min-width: 1024px) {
      padding: 15px 18px;
    }
  }
  .cancel-prescription-btn {
    position: relative;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #9747ff;
    box-sizing: border-box;
    width: 100%;
    padding: 7px 22px;
    font-size: 18px;
    line-height: 27px;
    color: #000000;
    opacity: 0.6;

    @media only screen and (min-width: 1024px) {
      padding: 13px 44px;
    }
  }
  .submit-prescription-btn {
    position: relative;
    border-radius: 10px;
    background-color: #9747ff;
    padding: 7px 22px;
    border: none;
    color: #fff;
    font-size: 18px;
    line-height: 27px;
    @media only screen and (min-width: 1024px) {
      padding: 13px 44px;
    }
  }
  .support-text-box-sec {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    position: relative;
    font-size: 16px;
    color: #000;
    white-space: pre-wrap;
    text-align: left;
    opacity: 0.6;
  }
  .remarks-prescription {
    margin-top: 10px;
    span {
      opacity: 0.6;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
    }
    textarea {
      border: 2px solid #e0e0e0;
      width: 100%;
      margin: 0;
      padding: 0.5rem;
      font-size: 14px;
      line-height: 24px;
    }
  }
  #dropzone-file {
    display: none;
  }
  .checkmark__circle {
    transform-origin: center;
    stroke-dasharray: 1000;
    fill-opacity: 0;
    animation: dash 3s linear, FillIn 3s 0.35s ease-in-out forwards;
  }

  .checkmark__tick {
    stroke-dasharray: 1000;
    stroke-dashoffset: -100;
    animation: dash-check 3s 0.35s ease-in-out forwards infinite;
  }

  @keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes FillIn {
    from {
      fill-opacity: 0;
    }
    to {
      fill-opacity: 1;
    }
  }

  @keyframes dash-check {
    0% {
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dashoffset: 900;
    }
  }
.patient-info-section {
    display: flex;
    grid-gap: 120px;
    gap: 120px;
}

.patient-details {
    display: flex;
}

.modal-header {
    justify-content: space-between !important;
}

.modal-header .btn-close {
    position: absolute;
    top: 12px;
    right: 12px;
}

.details h2 {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.26px;
}

.details .det-head {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.36px;
    opacity: 0.7;
}

.details p {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.42px;
    opacity: 0.8;
    margin-bottom: 8px;
}
.doctor-info-section .doc-name{
    color: #000;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.42px;
}
.doctor-info-section .doc-head{
    color: #000;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.42px;
opacity: 0.7;
}
.doctor-info-section p{
    color: #000;
    opacity: 0.8;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.42px;
margin-bottom:8px ;
}
.logo-image{
    width: 100px;
}
.body-head{
    color: #8A7878;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.42px;
}
.custom-table {
    width: 100%;
    overflow-x: auto;
  }
  .custom-table table{
    border: 1px solid #EBEBEB;
  }

  .custom-table .ant-table-thead {
    background: #dcf1fd;
    border-radius: 5px;
  }

  .custom-table .ant-table-thead > tr > th {
    background: #dcf1fd;
    border: none;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.36px;
    padding: 10px 22px;
  }

  .custom-table .ant-table-tbody > tr > td {
    color: #756767;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.36px;
    padding: 10px 22px;
  }

  .custom-table .ant-table-tbody > tr {
    margin: 10px 0;
    border-bottom: 1px solid #EBEBEB;;
  }
  .patient-cart{
    display: flex;
    grid-gap:12px;
    gap:12px;
    margin-top: 12px;
    justify-content: end;
    align-items: baseline;
  }
  .patient-cart p{
    color: #000;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.36px;
  }
  .patient-cart button{
    border-radius: 5px;
background: var(--Primary, #9747FF);
display: inline-flex;
padding: 10px;
justify-content: center;
align-items: center;
grid-gap: 10px;
gap: 10px;
color: var(--white, #FFF);
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.36px;
border: none;
  }
.patient-carttwo{
    display: flex;
    grid-gap:12px;
    gap:12px;
    margin-top: 12px;
}
.address p{
    color: #000;
margin-bottom: 6px;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.3px;
}


@media (max-width: 768px){
    .patient-info-section {
        display: block;
    }
    .doctor-info-section{
        float: inline-end;
    }
}
.bodymodal {
    max-height: 300px;
    overflow-y: scroll;
}

.form-label {
    color: var(--dummy, #808080);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.3px;
}

.form-control::placeholder {
    color: #ABABAB;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
}

.form-check-label {
    color: #ABABAB;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
}
.timings-text{
    color: #313131;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.48px;
}
.week-name .form-label{
    color: #000;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.form-check-label{
    color: #000;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.btn-save{
    border-radius: 5px;
background: var(--Primary, #9747FF) !important;
border-color: #9747FF !important;
}
.card-body svg{
    margin-top: 80px !important;
}
.custom-sidebar {
  position: fixed;
  top: 36px;
  right: -400px;
  /* Initially hide the sidebar off-screen */
  width: 400px;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transition: right 0.3s ease-in-out;
  z-index: 1000;
}

.custom-sidebar.open {
  right: 0;
  /* Slide the sidebar into view */
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-radius: 8px 0px 0px 0px;
  background: #D3EDFC;
}

.sidebar-header h3 {
  margin: 0;
  color: #313131;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.close-btn {
  background: none;
  border: none;
  color: #000;
  font-size: 24px;
  cursor: pointer;
}

.sidebar-content {
  padding: 15px;
  overflow-y: auto;
}

.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  /* Behind the sidebar but above everything else */
}

.audiance-sec {
  /* padding: 20px; */
  background-color: #f8f9fa;
}

.custom-pagination .page-link {
  color: #5856d6;
  border: none;
  background-color: transparent;
}

.custom-pagination .page-item.active .page-link {
  background-color: #5856d6;
  border-color: #5856d6;
}

.btn-cls .create-aud {
  background-color: #5856d6;
  color: #fff;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.create-aud svg {
  margin-left: 5px;
}

.modal-content {
  border-radius: 15px;
  padding: 20px;
}

.modal-header {
  border-bottom: none;
  padding-bottom: 0;
}

.modal-title {
  font-weight: 700;
  font-size: 1.5rem;
  color: #5856d6;
}

.upload-section {
  border: 2px dashed #5856d6;
  border-radius: 8px;
  padding: 30px;
  background-color: #f7f7ff;
  margin-bottom: 15px;
}

.upload-section p {
  font-size: 1rem;
  color: #5856d6;
  margin-top: 10px;
  font-weight: 500;
}

.text-success {
  color: #315E0E;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}

.popup-form .form-label {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.bulk-upload-icon {
  font-size: 1.5rem;
  color: #3ac47d;
  margin-right: 5px;
}

.audience-details .form-control {
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #ced4da;
  color: #000;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.modal-footer {
  border-top: none;
  padding-top: 0;
}

.modal-footer .btn-secondary {
  background-color: #f8f9fa;
  color: #6c757d;
  border: 1px solid #ced4da;
}

.modal-footer .btn-primary {
  background-color: #5856d6;
  border-color: #5856d6;
  padding: 10px 20px;
  font-weight: 600;
  border-radius: 8px;
}

.form-group label {
  font-weight: 500;
  color: #6c757d;
  font-size: 0.95rem;
}

.popup-form {
  display: flex;
  grid-gap: 12px;
  gap: 12px;
}

.btn-cls {
  text-align: -webkit-right;
}

.savenext {
  color: #F2FAFF !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  border-radius: 5px !important;
  background: var(--Primary, #9747FF) !important;
  border: none !important;
}

.savebtn {
  border-radius: 0px 5px 5px 0px !important;
  background: #F9ECEC !important;
  color: #000 !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  border: none !important;
  position: absolute;
  right: 18px;
}

.code-section .form-control {
  color: #684B4B;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.code-section .form-label {
  color: #684B4B;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.btn-link {
  background-color: transparent;
  border: none;
  text-decoration: none !important;
}

.message p {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 183.333% */
  letter-spacing: -0.12px;
}

.check {
  display: flex;
  justify-content: space-between;
  justify-items: center;
}

.check p {
  margin-top: 12px;
  margin-bottom: 0;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 183.333% */
  letter-spacing: -0.12px;
}

.send-btn {
  color: var(--white, #FFF) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 22px !important;
  /* 183.333% */
  letter-spacing: -0.12px !important;
  border-radius: 3px !important;
  background: var(--Primary, #9747FF) !important;
  border: none !important;
  margin-top: 20px !important;
  padding: 10px 30px !important;
  margin-bottom: 20px !important;
}

.message {
  border-radius: 5px;
  border: 1px solid #E4E3E3;
  background: var(--white, #FFF);
  padding: 12px;
  margin-bottom: 12px;
}

@media (max-width: 768px) {
  .btn-cls {
    text-align: center;
    margin-top: 20px;
  }

  .pagination {
    justify-content: center;
  }

  .create-aud {
    width: 100%;
  }
  .row{
    display: block !important;
  }
  .tab-buttons{
    display: inline-flex;
    margin-top: 12px;
  }
  .tab-buttons button{
    padding: 9px !important;
  }
  .profile-pic{
    display: none;
  }
  .create-aud{
    margin-bottom:10px;
    margin-top: 0 !important;
  }
  .communication .nav-tabs{
    display: grid;
  }
}
.reminder-sent-content {
    text-align: center;
}

img {
    width: 160px;
}

.modal-title {
    color: #313131 !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
}

.reminder-sent-content p {
    color: var(--dummy, #808080) !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
}
/* .modal-content {
    min-width: 609px;
    border: none;
    border-radius: unset;
}

.modal-body {
    display        : flex;
    justify-content: center;
    align-items    : center;
    min-height     : 195px;
} */

.modalBodyText {
  /* font-family: Noto Serif; */
  /* font-style : normal; */
  font-weight: normal;
  font-size: 25px;
  line-height: 40px;
  color: #000000;
  margin: 0 !important;
  text-align: center;
}

.modalCandelBtn {
  width: 154px;
  height: 50px;
  border: 1px solid #000000;
  background: #ffffff;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  margin-right: 16px;
}

.modalCandelBtn:hover,
.modalCandelBtn:focus,
.modalCandelBtn:active {
  background-color: #ffffff !important;
  border: 1px solid #000000 !important;
  color: #000000 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
}

.modalDeleteBtn {
  width: 148px;
  height: 50px;
  background: #000000 !important;
  border: none;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}

.modalDeleteBtn:hover,
.modalDeleteBtn:focus,
.modalDeleteBtn:active {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset !important;
  background-color: #000000 !important;
}

.top-header-sec-cont {
  height: 80px;
  display: flex;
  background: #fff;
  box-shadow: 2px 2px 19px 0px rgba(0, 0, 0, 0.1);
  justify-content: center;
  padding-top: 30px;
  ul {
    display: flex;
    grid-gap: 34px;
    gap: 34px;
    justify-content: center;
  }
  .select-option {
    border: none;
    font-weight: 600;
    margin-bottom: 8px;
  }
  li {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    list-style-type: none;
  }
  li.active {
    color: #9747ff;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
.header-page-sec-top-full {
  position: fixed !important;
}
.Toastify__toast-container {
  top: 5rem !important;
}
.Toastify__toast-container .Toastify__toast {
  background-color: #078c5f !important;
  color: #fff !important;
}
.delta-select__value-container {
  height: 44px;
}
.logo-top-top-left {
  height: 60px;
  display: inline-block;
  text-align: right;
  float: right;
  /* display: flex; */
  /* justify-content: end; */
  left: 40px;
  position: inherit;
}
.logo-top-top-left img {
  max-width: 140px;
  margin-top: -60px;
  cursor: pointer;
}
.cart-sec-header {
  display: inline-block;
  text-align: right;
  float: right;
  cursor: pointer;
  /* display: flex; */
  /* justify-content: end; */
  right: 40px;
  position: inherit;
}
.top-header-sec-cont {
  position: fixed;
  width: 100%;
  z-index: 9999999;
}
.rapha-header-sec-left img {
  height: 60px;
}

.rapha-header-sec {
  padding-top: 120px !important;
}
.empty-page-sub-header {
  padding-top: 80px !important;
}
.header-text-center1 {
  display: flex;
  grid-gap: 16px;
  gap: 16px;
}
.margin-top-button-sec {
  padding-top: 13px;
}
.header-text-center1 button,
.rapha-header-sec-right button {
  margin-top: 14px;
}
.rapha-header-sec {
  background: #fff;
  box-shadow: 2px 2px 19px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
}
.welcome-text-rapha {
  background: #f4f2f5;
  padding: 30px 50px;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
.rapha-header-sec {
  background: #fff;
  box-shadow: 2px 2px 19px 0px rgba(0, 0, 0, 0.1);
  padding: 13px 50px;
  position: relative;
  z-index: 1;
}
.rapha-header-sec input {
  border-radius: 10px;
  background: #fff;
  box-shadow: 2px 2px 19px 0px rgba(0, 0, 0, 0.1);
  height: 50px;
  width: 450px;
}
.rapha-header-sec-left {
  height: 60px;
  width: 120px;
}
.welcome-text-rapha-left {
  width: 60%;
}
.welcome-text-rapha-left h3 {
  color: #000;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 16px;
  /* opacity: 0.8; */
  span {
    color: #9747ff;
    font-weight: bold;
  }
}

.rapha-header-sec-right {
  button {
    border-radius: 10px;
    background: #9747ff;
    height: 42px;
    width: 92px;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: none;
  }
}
.error_message {
  color: red;
}
.footer-section-nme {
  background: #078c5f;
  padding: 60px 50px;
}
.footer-section-content {
  display: flex;
  grid-gap: 50px;
  gap: 50px;
  justify-content: space-between;
  h5 {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.28px;
    margin-bottom: 16px;
  }
  li {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0.42px;
    opacity: 0.8;
  }
}
.about-us-section-left {
  width: 55%;
}
.rapha-header-sec-left img {
}
.vector-4 {
  position: absolute;
  width: 15px;
  margin-left: -60px;
  height: 15px;
  margin-top: -12px;
  /* background: url(/assets/images/search.png) no-repeat center; */
  background-size: 100% 100%;
  opacity: 0.5;
}
.vector-5 {
  position: absolute;
  width: 15px;
  margin-left: -30px;
  height: 15px;
  margin-top: -12px;
  /* background: url(/assets/images/mic.png) no-repeat center; */
  background-size: 100% 100%;
  opacity: 0.5;
}

.text-center {
  text-align: center;
}
.header-sec-content-text-sec .active .custome-edit-text {
  font-weight: 700;
}
@media (max-width: 700px) {
  .top-header-sec-cont {
    padding-top: 20px;
    ul {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 10px;
      gap: 10px;
      padding: 0px 20px;
    }
    li {
      font-size: 14px;
    }
  }

  .header-text-center1 {
    display: none;
  }
  .rapha-header-sec {
    justify-content: space-between;
    padding: 10px;
    /* display: block; */
  }
  .rapha-header-sec-left {
    /* margin: auto; */
  }
  .welcome-text-rapha,
  .our-services-sec,
  .our-services-sec1,
  .empanel-sec,
  .about-us-section,
  .footer-section-nme {
    padding: 30px 20px;
    display: block;
  }
  .welcome-text-rapha-left,
  .about-us-section-left {
    width: 100%;
  }
  .book-app-doctor-actions {
    text-align: center;
  }
  .book-app-doctor {
    margin-top: 30px;
    margin-left: 0px;
  }
  .empanel-sec-top,
  .empanel-sec-bottom,
  .about-us-section-content {
    display: block;
  }
  .empanel-sec-bottom {
    padding: 20px;
  }
  .empanel-sec-bottom {
    height: auto;
  }
  .empanel-sec-img {
    display: none;
  }
  .empanel-sec-text {
    padding-top: 2px;
  }
  .empanel-sec-top-right {
    margin-top: 20px;
  }
  .our-services-all,
  .our-services-all1,
  .footer-section-content {
    grid-template-columns: 1fr 1fr;
    display: grid;
  }
  .footer-section-content {
    grid-template-columns: 1fr;
    display: grid;
  }
  .footer-section-content {
    grid-gap: 20px;
    gap: 20px;
  }
  .footer-row {
    /* margin-top: 40px; */
    h5 {
      margin-top: 10px;
    }
  }
  .empanel-sec-top > div {
    width: 100%;
  }
  .header-sec-content-text-sec .right-side-sec-header-box .navbar-brand {
    margin-right: 0px;
  }
  .header-sec-content-text-sec .right-side-sec-header-box .navbar-brand img {
    width: 22px;
  }

  .header-sec-content-text-sec .right-side-sec-header-box .navbar-brand .mt-2 {
    font-size: 14px;
    position: relative;
    top: -6px;
    left: -1px;
  }
  .right-side-sec-header-box button {
    font-size: 14px;
    padding: 5px;
  }
}
.bottom-footer {
  background: #d9d9d9;
  text-align: center;
}
.bottom-footer p {
  color: #000;
  text-align: center;
  /* font-family: Poppins; */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 12px 0;
}
.patent {
  padding: 36px 0;
}
.patentbtn {
  background: #c3ffeb;
  color: #000;
  /* font-family: Poppins; */
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 16px 60px;
}
/* Popup */
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  visibility: hidden;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it overlays everything */
}

.overlay:target {
  visibility: visible;
  opacity: 1;
}

.popup {
  width: 80%; /* Adjusted width for smaller screens */
  max-width: 680px; /* Maximum width for the popup */
  background: #fff;
  border-radius: 10px;
  box-shadow: 2px 2px 19px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  /* padding: 20px; */
}

.popup .close {
  position: absolute;
  top: 0px; /* Adjusted position to ensure close button is visible */
  right: 12px; /* Adjusted position to ensure close button is visible */
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
  transition: all 200ms;
}

.popup-content {
  /* Styling for the content inside the popup */
}

@media screen and (max-width: 768px) {
  .popup {
    width: 90%; /* Adjust width for smaller screens */
  }

  .popup .close {
    top: 8px;
    right: 8px;
  }
}
.Log-in {
  display: flex;
  width: 92px;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  border-radius: 10px;
  background: #9747ff;
  color: #fff;
  /* font-family: Poppins; */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 14px 0;
}
.login-Model-popup .modal-dialog {
  max-width: 680px;
  margin-top: 50px;
}
.cart-sec-header-full-right {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
.d-flex {
  display: flex;
}
.space-between {
  justify-content: space-between;
}
.cart-sec-header-full-right button {
  border-radius: 10px;
  background: #9747ff;
  height: 38px;
  width: 85px;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  margin-top: -6px;
}
.login-user-fnhidden {
  color: #7c4dff;
}
.model-product-order-conformation .modal-dialog {
  max-width: 630px !important;
}
.new-header-sec-cart {
  margin-top: -4px;
}
.add-customer-model-box1 .modal-dialog {
  margin-top: 70px;
}
.pac-container {
  z-index: 99999999;
}
.primary-button {
  border-radius: 4px;
  background-color: #9747ff;
  height: 50px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  padding: 10px 50px;
  border: none;
}
.cursor-pointer {
  cursor: pointer;
}
.secoundary-button {
  font-size: 16px;
  font-weight: 500;
  color: #9747ff;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #9747ff;
  box-sizing: border-box;
  height: 50px;
  padding: 10px 30px;
}

.dropbtn {
  color: #000;
  font-size: 16px;
  border: none;
  background: transparent;
  font-weight: 400;
  margin-top: 8px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* Media query for mobile view */
@media (max-width: 750px) {
  .dropdown-content a:hover {
    background-color: #ddd;
  }
  .dropdown:hover .dropdown-content {
    position: static;
    display: block;
    width: 50%;
    overflow: auto;
    height: 150px;
  }
}

.modal-content {
  margin-top: 6rem;
}
.modal-header {
  display: flex;
  justify-content: center;
}
.form-inline {
  display: flex;
  width: 30rem;
}
.selectedItem-div {
  font-weight: 600;
  color: #9747ff;
  font-size: 18px;
}
.cart-sec-header-full-right .header-city-btn {
  border-radius: 10px;
  border: 1px solid #7b6be6;
  box-sizing: border-box;
  background: #fff;
  color: #7b6be6;
  font-size: 14px;
  width: auto;
  padding: 3px 10px;
  opacity: 0.8;
}
.right-side-sec-header-box {
  display: flex;
}
.border-edit {
  position: fixed;
  z-index: 9;
  /* border-bottom: 1px solid black; */
  width: 100%;
}

.btn-custom-city {
  color: #000;
  border-color: #7b6be6;
}

.btn-custom-city:hover,
.btn-custom-city:focus,
.btn-custom-city:active {
  color: #fff;
  background-color: #675ac2;
  border-color: #675ac2;
}
.btn-custom-city-1 {
  color: #fff;
  background-color: #7b6be6;
  border-color: #7b6be6;
}
.offset-div {
  margin-left: 12.666667%;
}

.btn-custom-city:hover {
}
.btn-custom-city:focus,
.btn-custom-city:active {
  color: #fff;
  background-color: #675ac2;
  border-color: #675ac2;
}
.custome-edit-text {
  text-wrap: nowrap;
}
.user-img {
  display: none;
}
.search-div {
  display: none;
}
@media (max-width: 700px) {
  /* .search-div {
    display: block;
    width: 7rem;
  } */
  .left-image-sec-logo img {
    width: 90px;
    height: 50px;
    object-fit: contain;
  }
  .primary-button {
    border-radius: 4px;
    background-color: #9747ff;
    height: auto;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    padding: 10px 50px;
    border: none;
  }
  .secoundary-button {
    height: auto;
  }
}
@media (max-width: 991.98px) {
  .navbar-collapse {
    display: none;
  }
  .navbar-collapse.show {
    display: block;
  }
}
.brandImg {
  object-fit: contain;
}
.nav_bar {
  position: fixed;
  z-index: 9;
  width: 100%;
}

/* .conformation-model {
  padding: 30px;
} */
.conformation-model p {
  font-size: 25px;
  margin: 0px;
}
.modal-footer {
  display: flex;
  justify-content: center;
}
.conformation-model-box .modal-dialog {
  max-width: 500px;
}
.conformation-model .cancel-btn {
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding: 9px 5px;
  /* margin: 5px; */
  width: 100px;
  display: inline-block;
  text-align: center;
  /* color: #007bff; */
  cursor: pointer;
  font-size: 16px;
  border-radius: 10px;
  background: #fff;
  margin-right: 20px;
}
.conformation-model .delete-btn {
  text-transform: capitalize;
  background: steelblue;
  line-height: 35px;
  border-radius: 5px;
  min-width: 100px;
  text-align: center;
  color: #fff;
  border: none;
  padding: 0px 40px;
  height: 40px;
  font-size: 20px;
  /* margin-top: -2px; */
}

.bodySection {
  margin: auto;
  color: #333;
  /* background: #f5f5f5; */
}
body {
  /* font-family: inherit; */
  font-size: 16px;
  color: #333;
  /* background-color: #f5f5f5 !important; */
}
iframe {
  display: none;
}
.adminControlApp {
  min-height: 1000px;
  /* display: inline-block; */
  background: #f5f5f5;
}
.layout-container {
  /* padding-top: 70px; */
  width: 100%;
  margin: auto;
  padding-bottom: 0px;
  background-color: #f6f9fd;
  min-height: 100vh;
}
.header-page-sec-top-full {
  border-bottom: 1px solid #e2e2e2;
}
.full-content-sec-page-design {
  width: 100%;
  display: flex;
  /* margin-top: 70px; */
  grid-gap: 20px;
  gap: 20px;
}
.doctor-dashboard-body-content {
  padding-top: 100px;
  /* overflow: scroll; */
}

.left-menu-side-content {
  width: 217px;
  background: #fff;
  box-shadow: 2px 2px 19px 0px rgba(0, 0, 0, 0.05);
  background: #fff;
  border-right: 2px solid #e2e2e2;
  display: flex;
  padding: 2rem;
  justify-content: left;
  position: fixed;
  height: 100vh;
}
.right-menu-side-content {
  max-width: 85vw;
  width: calc(100% - 217px);
  margin-left: 220px;
}
.cursor-pointer {
  cursor: pointer;
}
@media (max-width: 300px) {
  .layout-container {
    margin-top: 5rem;
  }
}
@media (max-width: 768px) {
  .full-content-sec-page-design {
    display: block;
  }
  .left-menu-side-content {
    width: 100%;
    display: none;
  }
  .right-menu-side-content {
    width: 100%;
    max-width: 100%;
    padding: 0px 10px;
    margin-left: 0px;
  }
}

body {
  background: #f6f9fd;
}

h2.sectionHeading {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}

input[placeholder^="Search"] {
  min-height: 40px;
}


@media (min-width: 601px) {
    .show-mobile{
        display: none;
    }
}
@media (max-width: 600px) {
    .show-desktop{
        display: none;
    }
    .layout-container{
        width: 95%;
    }
    .freshbag-container{
        width: 100%;
    }
    .products-list {
        max-width: 100%;
        padding: 0px;
    }
    .products-list .product_cart{
        padding: 10px;
        display: block;
    }
    .products-list .product_cart > div{
        width: 100%;
    }
    .products-list .product_cart .cart_product-image img{
        max-width: 100%;
    }
    .product_cart .cart_product-image{
        width: 100%;
    }
    .product_cart .cart_actions{
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .cart-left-side-continer, .cart-right-side-continer{
        width: 100%;
        margin-bottom: 0px;
    }
    .cart-right-side-continer{
        padding-top: 10px;
    }
    .products-list-cart .product_cart{
        display: block;
    } 
    .products-list-cart .product_cart > div{
        width: 100%;
    } 
    .products-list-cart .product_cart .cart_product-actions{
        text-align: right;
        width: 95%;
    }
    .products-list-cart .cart_product-image img{
        max-width: 100%;
    }
    .LoginContainer {
        width: 100%;
    }
    .orderHistory-container{
        width: 90%;
    }
    .orderHistory-header-text{
        width: 100%;
    }
    .orderHistory-body .order-details-row{
        display: block;
    }
    .orderHistory-body .order-details-row > div{
        width: 100%;
    }
    .orderHistory-body .order-history-item-image{
        max-width: 100%;
    }
    .orderHistory-body .order-body-all-rows{
        padding-left: 20px;
    }
    .myProfile-container .profile-left-container, .myProfile-container .profile-right-container{
        width: 100%;
    }
    .profile-right-container .userProfile-wrapper > div{
        display: block;
    }
    .profileinfoHeader span{
        font-size: 20px;
    }
    .profileinfoHeader .profileEdit {
        font-size: 16px;
        margin-left: 10px;
    }





    

    /* create-product */
    .product-upload-section{
        width: 100%;
    }
    .product-upload-body .imageContainer{
        margin: 22px 6px 43px!important;
        width: 100%;
    }
  }

  @media (min-width: 601px) and (max-width: 1024px) {

  }
